// middleware リダイレクト用URLリスト

export type RedirectUrl = {
  target_url: string
  goto_url: string
}

/**
 * インタビューURLを snake_case -> kebab-case の変更に伴う、旧URL -> 新URLのリダイレクトリスト
 * ref. https://tential.slack.com/archives/C013KEPFLSV/p1718074209827759?thread_ts=1716264665.821209&cid=C013KEPFLSV
 */
const interviewUrls: RedirectUrl[] = [
  {
    target_url: "/interviews/cmag_kisuinakazawa",
    goto_url: "/interviews/cmag-kisuinakazawa",
  },
  {
    target_url: "/interviews/cmag_netarotsuneki",
    goto_url: "/interviews/cmag-netarotsuneki",
  },
  {
    target_url: "/interviews/cmag_annatsuchiya",
    goto_url: "/interviews/cmag-annatsuchiya",
  },
  {
    target_url: "/interviews/cmag_tsuyoshiwada",
    goto_url: "/interviews/cmag-tsuyoshiwada",
  },
  {
    target_url: "/interviews/cmag_fumioyonezawa",
    goto_url: "/interviews/cmag-fumioyonezawa",
  },
  {
    target_url: "/interviews/cmag_hirokazuibata",
    goto_url: "/interviews/cmag-hirokazuibata",
  },
  {
    target_url: "/interviews/cmag_chenelle",
    goto_url: "/interviews/cmag-chenelle",
  },
  {
    target_url: "/interviews/cmag_renatakeshita",
    goto_url: "/interviews/cmag-renatakeshita",
  },
]

/**
 * サブカテゴリページリダイレクト用URLリスト
 */
const subCategoryRedirectUrls: RedirectUrl[] = [
  // comfort-tech-wearとinner-wearに分裂したが、リダイレクトはcomfort-tech-wearに統一
  {
    target_url: "/work-support/work-wear",
    goto_url: "/day-conditioning/comfort-tech-wear",
  },
  {
    target_url: "/work-support/bath-salt",
    goto_url: "/night-conditioning/bath",
  },
  // 枕カテゴリは寝具カテゴリに統合された
  {
    target_url: "/recovery_wear_stock_info",
    goto_url: "/stock_info?category=recovery-wear",
  },
  {
    target_url: "/night-conditioning/pillow",
    goto_url: "/night-conditioning/bedding",
  },
  {
    target_url: "/night-conditioning/pillow/*",
    goto_url: "/night-conditioning/bedding",
  },
]

/**
 * カテゴリページリダイレクト用URLリスト
 */
const categoryRedirectUrls: RedirectUrl[] = [
  {
    target_url: "/sleep/recovery_wear/general-medical-devices",
    goto_url: "/general-medical-devices",
  },
  {
    target_url: "/sleep/*",
    goto_url: "/night-conditioning",
  },
  {
    target_url: "/sleep",
    goto_url: "/night-conditioning",
  },
  {
    target_url: "/foot-wear/*",
    goto_url: "/day-conditioning",
  },
  {
    target_url: "/foot-wear",
    goto_url: "/day-conditioning",
  },
  {
    target_url: "/work-support/*",
    goto_url: "/day-conditioning",
  },
  {
    target_url: "/work-support",
    goto_url: "/day-conditioning",
  },
  {
    target_url: "/philosophy",
    goto_url: "/story",
  },
  // BAKUNE以外の在庫状況ページを作成・共通化した
  {
    target_url: "/recovery_wear_stock_info",
    goto_url: "/stock_info?category=recovery-wear",
  },
]

/**
 * 商品ページリダイレクト用URLリスト
 */
const productRedirectUrls: RedirectUrl[] = [
  // page設定でリダイレクトされていた商品ページ
  {
    target_url: "/night-conditioning/recovery-wear/bakune-pajamas-long-set",
    goto_url: "/products/bakune-pajamas23ss-long-set",
  },
  {
    target_url: "/night-conditioning/recovery-wear/bakune-pajamas-long-shirt",
    goto_url: "/products/bakune-pajamas23ss-long-set",
  },
  {
    target_url: "/night-conditioning/recovery-wear/bakune-pajamas-long-pants",
    goto_url: "/products/bakune-pajamas23ss-long-set",
  },
  {
    target_url: "/day-conditioning/comfort-tech-wear/migaru-pull-hoodie",
    goto_url: "/products/migaru-work-wear-pull-hoodie23fw-set",
  },
  {
    target_url: "/day-conditioning/comfort-tech-wear/migaru-work-wear-pull-hoodie-set",
    goto_url: "/products/migaru-work-wear-pull-hoodie23fw-set",
  },
  {
    target_url: "/day-conditioning/work-support-accessory/recovery-leg-warmer",
    goto_url: "/products/leg-warmer",
  },
  // work-support
  {
    target_url: "/work-support/work-wear/*",
    goto_url: "/products",
  },
  {
    target_url: "/work-support/bath-salt/*",
    goto_url: "/products",
  },
  {
    target_url: "/work-support/mask/*",
    goto_url: "/products",
  },
  {
    target_url: "/work-support/medical-scrub/*",
    goto_url: "/products",
  },
  {
    target_url: "/work-support/work-support-accessory/*",
    goto_url: "/products",
  },
  // foot-wear
  {
    target_url: "/foot-wear/insole/*",
    goto_url: "/products",
  },
  {
    target_url: "/foot-wear/sandal/*",
    goto_url: "/products",
  },
  {
    target_url: "/foot-wear/socks/*",
    goto_url: "/products",
  },
  // sleep
  {
    target_url: "/sleep/recovery-wear/*",
    goto_url: "/products",
  },
  {
    target_url: "/sleep/bedding/*",
    goto_url: "/products",
  },
  {
    target_url: "/sleep/bath/*",
    goto_url: "/products",
  },
  {
    target_url: "/sleep/sleep-accessory/*",
    goto_url: "/products",
  },
  {
    target_url: "/sleep/baby/*",
    goto_url: "/products",
  },
  // day-conditioning
  {
    target_url: "/day-conditioning/comfort-tech-wear/*",
    goto_url: "/products",
  },
  {
    target_url: "/day-conditioning/insole/*",
    goto_url: "/products",
  },
  {
    target_url: "/day-conditioning/sandal/*",
    goto_url: "/products",
  },
  {
    target_url: "/day-conditioning/socks/*",
    goto_url: "/products",
  },
  {
    target_url: "/day-conditioning/work-support-accessory/*",
    goto_url: "/products",
  },
  {
    target_url: "/day-conditioning/mask/*",
    goto_url: "/products",
  },
  {
    target_url: "/day-conditioning/medical-scrub/*",
    goto_url: "/products",
  },
  {
    target_url: "/day-conditioning/inner-wear/*",
    goto_url: "/products",
  },
  {
    target_url: "/day-conditioning/water-absorption-shorts/*",
    goto_url: "/products",
  },
  {
    target_url: "/day-conditioning/travel-accessory/*",
    goto_url: "/products",
  },
  {
    target_url: "/day-conditioning/golf/*",
    goto_url: "/products",
  },
  // night-conditioning
  {
    target_url: "/night-conditioning/recovery-wear/*",
    goto_url: "/products",
  },
  {
    target_url: "/night-conditioning/bedding/*",
    goto_url: "/products",
  },
  {
    target_url: "/night-conditioning/bath/*",
    goto_url: "/products",
  },
  {
    target_url: "/night-conditioning/sleep-accessory/*",
    goto_url: "/products",
  },
  {
    target_url: "/night-conditioning/baby/*",
    goto_url: "/products",
  },
]
/**
 * リダイレクト用URLリスト target_url にはワイルドカードも使用可能
 */
export const RedirectUrls: RedirectUrl[] = [
  ...interviewUrls,
  ...subCategoryRedirectUrls,
  ...categoryRedirectUrls,
  ...productRedirectUrls,
]
