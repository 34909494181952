import { initializeApp } from "firebase/app"
import { getAnalytics, setUserProperties, isSupported } from "firebase/analytics"
import { useLocalUser } from "~/composables/useLocalUser"

interface FirebaseConfig {
  apiKey: string
  authDomain: string
  databaseURL: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
  measurementId: string
}

export default defineNuxtPlugin(async () => {
  const { getLocalUserId } = useLocalUser()
  const runtimeConfig = useRuntimeConfig()
  const firebaseConfig = (): FirebaseConfig => {
    if (["production"].includes(runtimeConfig.public.APP_ENV || "")) {
      return {
        apiKey: "AIzaSyCX3cP3vpQmEjd6jry-iUJDl2PcHCr2h8k",
        authDomain: "tential-db.firebaseapp.com",
        databaseURL: "https://tential-db.firebaseio.com",
        projectId: "tential-db",
        storageBucket: "tential-db.appspot.com",
        messagingSenderId: "193994523800",
        appId: "1:193994523800:web:e4a010cebe037de5937ced",
        measurementId: "G-2JSSNDGP48",
      }
    } else {
      return {
        apiKey: "AIzaSyAKI0UKnmGihb8PKKIYdIYqQ3NW-5YPIoQ",
        authDomain: "tential-ec-dev.firebaseapp.com",
        databaseURL: "https://tential-ec-dev.firebaseio.com",
        projectId: "tential-ec-dev",
        storageBucket: "tential-ec-dev.appspot.com",
        messagingSenderId: "806879002553",
        appId: "1:806879002553:web:d88f76c22c9625c6d28068",
        measurementId: "G-HXD478KRY6",
      }
    }
  }
  const config = firebaseConfig()
  const firebase = initializeApp(config)
  if (await isSupported()) {
    const analytics = getAnalytics(firebase)

    const globalState = localStorage.getItem("GlobalState")
    const localUserId = getLocalUserId()
    if (globalState) {
      const { user } = JSON.parse(globalState)
      if (user && user.document_id) {
        setUserProperties(analytics, { user_id: user.document_id })
      } else {
        setUserProperties(analytics, { user_id: localUserId })
      }
    }
  }
})
