import { request } from "graphql-request"
import { TOKEN_EXPIRED_MESSAGE } from "@tential/ec-gql-schema/const/error"
import useCookieState from "~/composables/useCookieState"
import { SignInTokenRefreshDocument, VerifyUserTokenDocument } from "~/gql/urql.generated"
import { useStore } from "~/stores"
import { RedirectUrls, RedirectUrl } from "~/const/redirect_url"
import { SignInTokenRefreshMutation, VerifyUserTokenMutation } from "~/types/type.generated"

const buildSignInPath = (fullPath: string): string => {
  if (fullPath.includes("sign_in")) {
    return `/sign_in`
  }
  return `/sign_in?next=${fullPath}`
}

const isValidRefreshToken = async (endpoint: string, refreshToken: string | undefined) => {
  if (!refreshToken) return
  try {
    const refreshRes = await request<SignInTokenRefreshMutation>(
      endpoint,
      SignInTokenRefreshDocument,
      {
        refreshToken,
      },
      {
        refreshToken,
        "x-graphql-client": "urql", // used in basic-auth
      },
    )
    return refreshRes.signInTokenRefresh
  } catch (e) {
    return undefined
  }
}

const isValidAccessToken = async (endpoint: string, accessToken: string, refreshToken: string | undefined) => {
  try {
    const data = await request<VerifyUserTokenMutation>(
      endpoint,
      VerifyUserTokenDocument,
      {},
      {
        custom_authorization: accessToken ? `Bearer ${accessToken}` : "",
        "x-graphql-client": "urql", // used in basic-auth
      },
    )
    if (!data?.verifyUserToken) throw new Error(TOKEN_EXPIRED_MESSAGE)

    return {
      isValid: true,
    }
  } catch (e) {
    if (e instanceof Error && e.message.includes(TOKEN_EXPIRED_MESSAGE)) {
      const user = await isValidRefreshToken(endpoint, refreshToken)
      return {
        isValid: !!user,
        user,
      }
    }
  }
}

export default defineNuxtRouteMiddleware(async (to) => {
  const config = useRuntimeConfig()
  const { getAccessToken, signOutCookie, getRefreshToken, setSignInCookie } = useCookieState()
  const store = useStore()

  // クエリ文字列の取得
  const getQueryString = (fullPath: string) => {
    const splitNum = fullPath.indexOf("?") + 1
    return splitNum > 0 ? fullPath.substring(splitNum - 1) : ""
  }

  // リダイレクト処理
  const handleRedirect = () => {
    const redirectUrl = RedirectUrls.find((el: RedirectUrl) => {
      const regex = new RegExp(`^${el.target_url.replace(/\*/g, ".*")}$`)
      return regex.test(to.path)
    })

    if (redirectUrl) {
      const match = to.path.match(new RegExp(`^${redirectUrl.target_url.replace(/\*/g, "(.*)")}$`))
      const dynamicPart = match ? match[1] : ""

      const gotoPath = dynamicPart ? `${redirectUrl.goto_url}/${dynamicPart}`.replace(/\/+$/, "") : redirectUrl.goto_url
      return navigateTo(gotoPath + getQueryString(to.fullPath), { replace: true, redirectCode: 301 })
    }
  }

  // トークンの検証処理
  const handleTokenValidation = async () => {
    const token = getAccessToken()

    if (!token) {
      if (store.user.isSigned) store.resetOut()
      if (["mypage", "return_or_exchange", "survey_question"].some((path) => to.path.includes(path))) {
        return navigateTo(buildSignInPath(to.fullPath))
      }
      return
    }

    const res = await isValidAccessToken(config.public.GRAPHQL_ENDPOINT, token, getRefreshToken())
    if (res?.isValid) {
      if (res.user) {
        setSignInCookie(res.user.access_token, res.user.refresh_token)
        store.setUser({
          document_id: res.user.document_id,
          email: res.user.email,
          token: res.user.access_token,
          isSigned: true,
        })
      }
      return
    }

    signOutCookie()
    store.resetOut()

    if (["mypage", "return_or_exchange", "survey_question"].some((path) => to.path.includes(path))) {
      return navigateTo(buildSignInPath(to.fullPath))
    }
  }

  // リダイレクト処理
  const redirectResult = handleRedirect()
  if (redirectResult) return redirectResult

  // ページ移動時の処理
  if (to.path.includes("healthcheck")) return

  // トークン検証処理
  return await handleTokenValidation()
})
