import dayjs from "dayjs"
import { LayoutType } from "@tential/ec-gql-schema/master/pageParts"
import { calcPrice } from "@tential/ec-gql-schema/utils/functions/price"
import type { Sku } from "@tential/ec-gql-schema/models/sku"
import type { Step, PaymentType } from "~/types/order"

type SizeRecommendEventArg = {
  product_id: string
  gender: string
  height?: number
  weight?: number
  foot_size?: number
  recommend_size: string
}

type PurchaseStepArg = {
  step: Step
  payment_type: PaymentType
  is_egift?: boolean
  is_konbini_pickup?: boolean
  is_skip?: boolean
}

type SignInArg = {
  user_id: string
  sign_in_method: "email" | "line" | "amazon_pay"
  is_sign_up?: boolean
}

export const BannerType = {
  ...LayoutType,
  top_page: "TOPページ",
} as const

export const useGtagEvent = () => {
  const gtag = useGtag()
  const config = useRuntimeConfig()
  const route = useRoute()
  const isProd = config.public.APP_ENV === "production"

  const gtagEvent = (name: string, params?: Record<string, any>) => {
    if (isProd) {
      gtag("event", name, params)
    } else {
      // 本番以外の場合は確認しやすいようにログを出しておく
      console.info("gtagEvent", name, params)
    }
  }

  const trackAddToCart = (skus: Sku[]): void => {
    const items = skus.map((sku) => ({
      item_id: sku.document_id,
      item_name: sku.name,
      price: calcPrice(sku).effective_price_exclude_tax,
    }))

    // 合計価格を計算
    const totalValue = items.reduce((sum, item) => sum + item.price, 0)

    gtagEvent("add_to_cart", {
      currency: "JPY",
      value: totalValue,
      items,
      path: route.fullPath,
    })
  }

  const trackBannerClick = (parts: keyof typeof BannerType, uniqKey: string, index: number, alt: string): void => {
    if (!isProd) return
    const partsName = BannerType[parts]

    const bannerId = alt || `${parts}-${uniqKey}`
    gtag("event", "banner_click", {
      banner_id: bannerId,
      banner_index: index,
      parts_name: partsName,
      timestamp: dayjs().valueOf(),
      path: route.fullPath,
    })
  }

  const trackRecommendProductClick = (productId: string, recommendId: string, displayType: string) => {
    if (!isProd) return
    gtag("event", "click_recommend_item", {
      recommend_id: recommendId,
      display_type: displayType,
      product_id: productId,
    })
  }

  const trackRecommendProductView = (productId: string, recommendId: string, displayType: string) => {
    if (!isProd) return
    gtag("event", "inview_recommend_item", {
      recommend_id: recommendId,
      display_type: displayType,
      product_id: productId,
    })
  }

  const trackSizeRecommendClick = ({
    product_id,
    gender,
    height,
    weight,
    foot_size,
    recommend_size,
  }: SizeRecommendEventArg) => {
    if (!isProd) return
    gtag("event", "click_size_recommend", {
      product_id,
      gender,
      height,
      weight,
      foot_size,
      recommend_size,
    })
  }

  const trackCategoryClick = (category: string) => {
    if (!isProd) return
    gtag("event", "click_category", {
      category,
      path: route.fullPath,
    })
  }

  const trackProductListItemClick = (product_id: string) => {
    if (!isProd) return
    gtag("event", "click_product_list_item", {
      product_id,
      path: route.fullPath,
    })
  }

  const trackSignIn = ({ user_id, sign_in_method, is_sign_up = false }: SignInArg) => {
    gtagEvent("sign_in", {
      user_id,
      sign_in_method,
      is_sign_up: is_sign_up ? 1 : 0,
    })
  }

  const trackPurchaseStep = ({
    step,
    payment_type,
    is_egift = false,
    is_konbini_pickup = false,
    is_skip = false,
  }: PurchaseStepArg) => {
    gtagEvent(`purchase_step_${step}`, {
      payment_type,
      is_egift: is_egift ? 1 : 0,
      is_konbini_pickup: is_konbini_pickup ? 1 : 0,
      is_skip: is_skip ? 1 : 0,
    })
  }

  return {
    trackAddToCart,
    trackBannerClick,
    trackRecommendProductClick,
    trackRecommendProductView,
    trackSizeRecommendClick,
    trackCategoryClick,
    trackProductListItemClick,
    trackSignIn,
    trackPurchaseStep,
  }
}
