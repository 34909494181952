export const useLocalUser = () => {
  const cookieLocalUserId = useCookie("localUserId")

  const getLocalUserId = (): string => {
    // localUserIdがlocalstorageにある場合はそっちを使う、またcookieに反映させる
    // iOSは7日間アクセスがないとlocalstorageが消えしまうが、そのほかの場合は消えないので、localstorageから取得する処理はしばらく残した方がいい
    const localUserId = process.client ? localStorage.getItem("localUserId") : null
    if (localUserId) {
      if (cookieLocalUserId.value !== localUserId) {
        cookieLocalUserId.value = localUserId
      }
    }

    if (!cookieLocalUserId.value) {
      console.error("localUserId is not set")
      // TODO: SSR時のエラーを解消するため一時的にコメントアウト
      // waitForNREUM().then((nreum) => nreum.noticeError("localUserId is not set"))
    }

    return cookieLocalUserId.value || ""
  }

  return {
    getLocalUserId,
  }
}
