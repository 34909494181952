// plugins/body2fit.client.ts
export default defineNuxtPlugin(() => {
  // クライアントサイドのみで実行
  const script = document.createElement("script")
  script.src = "https://widget.body2fit.bodygram.com/v1/widget.js"
  script.async = true
  document.head.appendChild(script)

  const link = document.createElement("link")
  link.rel = "stylesheet"
  link.href = "https://widget.body2fit.bodygram.com/v0/widget.css"
  document.head.appendChild(link)
})
