<template>
  <div>
    <div v-if="state.isSSR">
      <NuxtLayout />
    </div>
    <div v-else>
      <ClientOnly>
        <NuxtLayout />
        <template #fallback>
          <OrganismsLoading />
        </template>
      </ClientOnly>
    </div>
  </div>
</template>

<script setup lang="ts">
const searchEngineBots = [
  "AdsBot-Google",
  "Google",
  "Googlebot",
  "Bingbot",
  "Slurp",
  "DuckDuckBot",
  "Baiduspider",
  "YandexBot",
  "facebookexternalhit",
  "bot",
]

const headers = useRequestHeaders(["user-agent"])
const userAgent = headers["user-agent"] || ""
const state: any = reactive({
  isSSR: false,
})
const url = useRequestURL()
const config = useRuntimeConfig()

if (url.pathname === "/healthcheck" || config.public.APP_ENV === "development") {
  state.isSSR = true
} else {
  state.isSSR = searchEngineBots.some((bot) => userAgent.includes(bot))
}
</script>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.2s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
}
.layout-enter-active,
.layout-leave-active {
  transition: all 0.2s;
}
.layout-enter-from,
.layout-leave-to {
  opacity: 0;
}
</style>
