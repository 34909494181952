/**
 * APIリクエストをしてcookieにlocalUserIdをセットする
 */
export default defineNuxtPlugin(async () => {
  try {
    await $fetch("/api/services/set_local_user_id", {
      method: "GET",
      retry: 3,
    })
  } catch (e) {
    console.error(e)
  }
})
