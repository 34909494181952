import { defineStore } from "pinia"
import type { CartItem, DuvetCollectionType } from "@tential/ec-gql-schema/models/order"
import { ShippingReferredType } from "@tential/ec-gql-schema/master/order"
import { ContactCategoryKeys, SubCategoryListKeys } from "@tential/ec-gql-schema/master/contact"
import dayjs from "dayjs"
import {
  EGiftWithOrderFragment,
  Coupon,
  PublicSearchSiteProductQuery,
  CouponWithUserCouponIdFragment,
  ReviewCreateInput,
} from "~/types/type.generated"
import { EGiftSelect, EGiftSkuByProduct, ShippingInfo, SkuByProduct } from "~/types/eGift"
import { ContactStoreState } from "~/types/contact"
import { RouteLog } from "~/types/routeLog"
import { AdTrackingParams } from "~/types/ad"
import { VisitStore } from "~/types/store"
import { cloneDeep } from "~/utils/functions/common"
import {} from "pinia-plugin-persistedstate"
import { initOrderFormData, initKonbiniData } from "~/utils/const/order"
import { initReviewFormData } from "~/utils/const/review"
import { OrderState } from "~/types/order"
import { InnerArray } from "~/types/utility"

/**
 * 商品詳細ページ(pages/[categorySlug]/[subCategorySlug]/[productSlug])
 * で商品の取得に使用するクエリの型
 */
export type Product = InnerArray<PublicSearchSiteProductQuery["publicSearchSiteProduct"]> & {
  // 商品を見た日時
  pv_timestamp?: number
}

export const state = () => ({
  cartList: Array<CartItem>(),
  cartListBySite: Array<{
    siteId: string
  }>(),
  stashCartList: Array<CartItem>(),
  contact: {
    name: "",
    email: "",
    confirmEmail: "",
    channel: "",
    type_id: 0,
    type_name: "",
    category: "before_order" as ContactCategoryKeys,
    sub_category: "about_the_product" as SubCategoryListKeys,
    support_status: "",
    product_name: "",
    order_id: "",
    content: "",
    files: Array<File>(),
    site_id: "",
    local_user_id: "",
    image_urls: Array<string>(),
    is_product_guide: false,
  },
  routeLogs: Array<RouteLog>(),
  purchaseEventSent: false,
  productBrowsingHistory: Array<Product>(),
  order: {
    document_id: "",
    total: 0,
    form: cloneDeep(initOrderFormData),
    reservationSkusScheduledDeliveryDate: "",
  },
  coupon: {
    couponCode: "",
    expired: 0,
    myCoupon: null as CouponWithUserCouponIdFragment | null,
  },
  distributedCoupon: {
    coupons: Array<Omit<Coupon, "_id">>(),
    isNotModal: false,
  },
  user: {
    isSigned: false,
    document_id: "",
    site_id: null as string | null,
    data: {},
    email: "",
    token: "",
  },
  headerRef: "",
  trackingParams: {
    ad_url: "",
    to_url: "",
  },
  trafficParams: {
    full_path: "",
  },
  switchFalseEveryFeatureFlag: false,
  socialId: "",
  socialSignUpReceiveMail: false,
  /** 贈り側のeギフト設定内容 */
  eGift: {
    message: "",
    giver_name: "",
    is_select: false,
    isAnonymous: false,
  },
  /** 受け取り側のeギフト情報 */
  eGiftData: {} as EGiftWithOrderFragment,
  /** 受け取り側で設定不要のeギフト商品ID */
  eGiftIgnoreSelectOrderProductIds: Array<string>(),
  eGiftShippingInfo: {
    shipping_referred_type: "standard_delivery" as (typeof ShippingReferredType)[number],
    shipping_referred_date: "",
    shipping_referred_time: "",
    scheduled_shipping_date: "",
  },
  eGiftSkuByProducts: {
    eGift_id: "",
    skuByProducts: Array<SkuByProduct>(),
  },
  orderState: {
    formData: cloneDeep(initOrderFormData),
    isSameAddress: true,
    paymentType: "credit_card_batch",
    selectedUserAddressIds: {
      billing: "",
      delivery: "",
    },
    konbiniData: cloneDeep(initKonbiniData),
  } as Pick<OrderState, "formData" | "isSameAddress" | "paymentType" | "selectedUserAddressIds" | "konbiniData">,
  reviewForm: initReviewFormData(),
  duvetCollectionType: "none" as DuvetCollectionType,
  visitStore: {
    storeId: "",
    effectiveUnixtime: 0,
    isExpired: true,
  },
})

export type RootState = ReturnType<typeof state>

interface UserPayload {
  document_id: string
  site_id?: string
  data?: Record<string, unknown>
  email?: string
  token?: string
  isSigned?: boolean
}

const injectSameItem = (list: CartItem[], item: CartItem): CartItem[] => {
  let sameItemIndex = null
  // 同じ商品の一番後ろに追加したいのでfindLastを使う（add_to_cart_timestampの順に並べたいため）
  const sameItem = list.findLast((_item, _index) => {
    if (
      _item.sku_id === item.sku_id &&
      _item.product_id === item.product_id &&
      _item.gift?.sku_id === item.gift?.sku_id &&
      _item.gift?.message === item.gift?.message &&
      _item.gift?.card === item.gift?.card &&
      _item.embroidery?.text === item.embroidery?.text &&
      _item.embroidery?.font === item.embroidery?.font &&
      _item.embroidery?.color === item.embroidery?.color &&
      _item.embroidery?.position === item.embroidery?.position &&
      _item.group_wrapped_uniq_id === item.group_wrapped_uniq_id &&
      !!_item.group_wrapping === !!item.group_wrapping &&
      _item.group_wrapping?.wrapping_sku_ids === item.group_wrapping?.wrapping_sku_ids &&
      _item.group_wrapping?.gift?.sku_id === item.group_wrapping?.gift?.sku_id &&
      _item.group_wrapping?.gift?.card === item.group_wrapping?.gift?.card &&
      _item.group_wrapping?.gift?.message === item.group_wrapping?.gift?.message
    ) {
      sameItemIndex = _index
      return true
    }

    return false
  })

  if (sameItemIndex !== null && sameItem) {
    const index = sameItemIndex + 1
    const addItem = cloneDeep(sameItem)
    addItem.add_to_cart_timestamp = item.add_to_cart_timestamp
    list.splice(index, 0, addItem)

    return list
  } else {
    return [...list, item]
  }
}

export const useStore = defineStore({
  id: "root",
  state,
  actions: {
    // カートリストに商品(SKU)を追加
    addCartList(cartItem: CartItem): void {
      this.cartList = injectSameItem(this.cartList, {
        ...cloneDeep(cartItem),
        add_to_cart_timestamp: dayjs().unix(), // 秒（ミリ秒ではないことに注意）
      })
    },

    setEGift(_eGift: EGiftSelect): void {
      this.eGift = _eGift
    },

    resetEGift(): void {
      this.eGift = {
        message: "",
        giver_name: "",
        is_select: false,
        isAnonymous: false,
      }
    },

    setSocialSignUpReceiveMail(_socialSignUpReceiveMail: boolean): void {
      this.socialSignUpReceiveMail = _socialSignUpReceiveMail
    },

    setEGiftData(_eGiftData: EGiftWithOrderFragment): void {
      this.eGiftData = _eGiftData
    },

    resetGiftData(): void {
      this.eGiftData = {}
    },

    setEGiftIgnoreSelectOrderProductIds(_productIds: string[]): void {
      this.eGiftIgnoreSelectOrderProductIds = _productIds
    },

    setEGiftShippingInfo(_eGiftShippingInfo: ShippingInfo): void {
      this.eGiftShippingInfo = {
        ..._eGiftShippingInfo,
        scheduled_shipping_date: _eGiftShippingInfo.scheduled_shipping_date ?? "",
      }
    },

    resetEGiftShippingInfo(): void {
      this.eGiftShippingInfo = {
        shipping_referred_type: "standard_delivery",
        shipping_referred_date: "",
        shipping_referred_time: "",
        scheduled_shipping_date: "",
      }
    },

    setEGiftSkuByProducts(_eGiftSkuByProducts: EGiftSkuByProduct): void {
      this.eGiftSkuByProducts = _eGiftSkuByProducts
    },

    resetEGiftSkuByProducts(): void {
      this.eGiftSkuByProducts = {
        eGift_id: "",
        skuByProducts: [],
      }
    },

    setOrderState(_orderState: OrderState): void {
      const orderState = cloneDeep(_orderState)
      this.orderState.formData = orderState.formData
      this.orderState.isSameAddress = orderState.isSameAddress
      this.orderState.paymentType = orderState.paymentType
      this.orderState.selectedUserAddressIds = orderState.selectedUserAddressIds
      this.orderState.konbiniData = orderState.konbiniData
    },

    resetOrderState() {
      this.orderState.formData = cloneDeep(initOrderFormData)
      this.orderState.isSameAddress = true
      this.orderState.paymentType = "credit_card_batch"
      this.orderState.selectedUserAddressIds = {
        billing: "",
        delivery: "",
      }
      this.orderState.konbiniData = cloneDeep(initKonbiniData)
    },

    resetRouteLogs(): void {
      this.routeLogs = []
    },

    addRouteLog(routeLog: RouteLog): void {
      if (this.routeLogs.length >= 100) {
        this.routeLogs = this.routeLogs.slice(-99)
      }
      this.routeLogs.push(routeLog)
    },

    sentEvent(): void {
      this.purchaseEventSent = true
    },

    resetSentEvent(): void {
      this.purchaseEventSent = false
    },

    addProductBrowsingHistory(product: Product): void {
      const newHistory = this.productBrowsingHistory.filter((prod) => prod.document_id !== product.document_id)
      if (newHistory.length > 25) {
        newHistory.pop()
      }
      // pv_timestampは秒（ミリ秒ではないことに注意）
      newHistory.unshift({ ...product, pv_timestamp: dayjs().unix() })
      this.productBrowsingHistory = newHistory
    },

    setTrafficParams(referrer: string): void {
      this.trafficParams = {
        full_path: referrer,
      }
    },

    trackingPost(params: AdTrackingParams): void {
      this.trackingParams = {
        ...params,
      }
    },

    resetTracking(): void {
      this.trackingParams = {
        ad_url: "",
        to_url: "",
      }
    },

    resetTraffic(): void {
      this.trafficParams = {
        full_path: "",
      }
    },

    setUser(payload: UserPayload) {
      this.user.document_id = payload.document_id
      this.user.site_id = payload.site_id ?? null
      this.user.email = payload.email ?? ""
      this.user.token = payload.token ?? ""
      this.user.isSigned = payload.isSigned ?? false
      if (payload.data) this.user.data = payload.data
    },

    resetOut() {
      this.user = {
        isSigned: false,
        document_id: "",
        site_id: null,
        email: "",
        token: "",
        data: {},
      }
    },

    initializeSocialId() {
      this.socialId = ""
    },

    mergeOrder(_order: Record<string, unknown>) {
      this.order = {
        ...this.order,
        ..._order,
      }
    },

    setHeader(headerRef: HTMLElement) {
      this.headerRef = String(headerRef)
    },

    setContact(contact: ContactStoreState) {
      this.contact = contact
    },
    setCouponCode(payload: { couponCode?: string; expired: number; myCoupon?: CouponWithUserCouponIdFragment }) {
      this.coupon = {
        couponCode: payload.couponCode || "",
        expired: payload.expired,
        myCoupon: payload.myCoupon || null,
      }
    },
    setDistributedCoupon(payload: { coupons: Omit<Coupon, "_id">[]; isNotModal?: boolean }) {
      this.distributedCoupon.coupons = payload.coupons
      this.distributedCoupon.isNotModal = !!payload.isNotModal
    },
    setReview(reviewForm: { review: ReviewCreateInput; confirm: boolean; isAnonymous: boolean }) {
      this.reviewForm = reviewForm
    },
    setReviewRecordId(recordId: string) {
      this.reviewForm.recordId = recordId
    },
    resetCartList() {
      this.cartList = []
    },
    resetContact() {
      this.contact = {
        channel: "",
        confirmEmail: "",
        content: "",
        email: "",
        image_urls: [],
        files: [],
        local_user_id: "",
        name: "",
        order_id: "",
        product_name: "",
        site_id: "",
        type_id: 0,
        type_name: "",
        category: "before_order",
        sub_category: "about_the_product",
        support_status: "",
        is_product_guide: false,
      }
    },
    resetOrder() {
      this.order = {
        document_id: "",
        total: 0,
        form: cloneDeep(initOrderFormData),
        reservationSkusScheduledDeliveryDate: "",
      }
    },
    resetDistributedCoupon() {
      this.distributedCoupon.coupons = []
      this.distributedCoupon.isNotModal = false
    },
    resetCoupon(): void {
      this.coupon = {
        couponCode: "",
        expired: 0,
        myCoupon: null,
      }
    },
    resetReview() {
      this.reviewForm = initReviewFormData()
    },
    setVisitStore(visitStore: VisitStore) {
      this.visitStore = visitStore
    },
    resetVisitStore() {
      this.visitStore = {
        storeId: "",
        effectiveUnixtime: 0,
        isExpired: true,
      }
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
})

if (process.client) {
  window.addEventListener("storage", (event) => {
    // 別タブや別ウインドウでカートが変更されたらそれを反映させる
    if (event.key === "root") {
      const oldValue = JSON.parse(event.oldValue || "{}")
      const newValue = JSON.parse(event.newValue || "{}")
      if (
        JSON.stringify(oldValue.cartList || []) !== JSON.stringify(newValue.cartList || []) ||
        oldValue.duvetCollectionType !== newValue.duvetCollectionType
      ) {
        const store = useStore()
        if (JSON.stringify(oldValue.cartList || []) !== JSON.stringify(newValue.cartList || [])) {
          store.cartList = newValue.cartList
        }
        if (oldValue.duvetCollectionType !== newValue.duvetCollectionType) {
          store.duvetCollectionType = newValue.duvetCollectionType
        }
      }
    }
  })
}
