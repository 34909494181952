import revive_payload_client_YTGlmQWWT5 from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_SiLaiDf04L from "/app/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import payload_client_VTarsGAzgb from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_xKuw1km5sV from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/apps/consumer/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_kpnqASteq8 from "/app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_r3pgF3B1Uq from "/app/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_iusAUtqzOR from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_SeG0EjL5Ec from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_10gaYeCiJK from "/app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import _01_set_local_user_id_WXSzzjzFpw from "/app/apps/consumer/plugins/01.set-local-user-id.ts";
import bodygram_client_u5EdBls4PH from "/app/apps/consumer/plugins/bodygram.client.ts";
import firebase_Wjz9XrZo01 from "/app/apps/consumer/plugins/firebase.ts";
import onGlobalSetup_87AqM8FyX7 from "/app/apps/consumer/plugins/onGlobalSetup.ts";
import urql_FlK1jaV38U from "/app/apps/consumer/plugins/urql.ts";
import vue_scrollto_lZXr206Htt from "/app/apps/consumer/plugins/vue-scrollto.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  _0_siteConfig_SiLaiDf04L,
  payload_client_VTarsGAzgb,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_kpnqASteq8,
  plugin_client_r3pgF3B1Uq,
  plugin_iusAUtqzOR,
  chunk_reload_client_SeG0EjL5Ec,
  check_outdated_build_client_LIYcCMJD18,
  plugin_10gaYeCiJK,
  _01_set_local_user_id_WXSzzjzFpw,
  bodygram_client_u5EdBls4PH,
  firebase_Wjz9XrZo01,
  onGlobalSetup_87AqM8FyX7,
  urql_FlK1jaV38U,
  vue_scrollto_lZXr206Htt
]