import type { DuvetCollectionType } from "@tential/ec-gql-schema/models/order"
import dayjs from "dayjs"
import { useStore } from "~/stores"

export const useDuvetCollection = () => {
  const store = useStore()

  const getDuvetCollectionType = (): DuvetCollectionType => {
    return store.duvetCollectionType
  }

  const setDuvetCollectionType = (duvetCollectionType: DuvetCollectionType) => {
    store.duvetCollectionType = duvetCollectionType
  }

  const showDuvetCollection = () => {
    // 2025-03-04 12:00以降から利用開始、@tential.jpの場合は常時表示（確認用）
    return dayjs().isAfter(dayjs("2025-03-04 12:00")) || isTentialUser()
  }

  const isTentialUser = () => {
    return store.user.email.includes("@tential.jp")
  }

  return {
    getDuvetCollectionType,
    setDuvetCollectionType,
    showDuvetCollection,
    isTentialUser,
  }
}
